import axios from 'axios';

export const registerUser = async (user) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/invest/clients`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
      },
    );
    if (!response.ok) {
      return response.json();
    }
    return response.json();
  };

  export const loginUser = async (loginData) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      },
    );
    if (!response.ok) {
      return response.json();
    }
    return response.json();
  };



  export const confirmationEmail = async (email) => {


  console.log(email);


    const config = {
      headers: {
        ContentType: `Content-Type': 'application/json`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/confirmEmail`,
      {email: email},
      config,
    );
    return response.data;


  };


  export const   missingPassword = async (email) => {
    console.log(email);
      const config = {
        headers: {
          ContentType: `Content-Type': 'application/json`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/invest/clients/missingPassword`,
        {email: email},
        config,
      );
      return response.data;
    };


    export const securityCheckChangePasswordByUser = async (email, code) => {
      console.log(email, code);
        const config = {
          headers: {
            ContentType: `Content-Type': 'application/json`,
          },
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/invest/clients/securityCheckChangePasswordByUser`,
          {email: email,
          code: code},
          config,
        );
        return response.data;
      };



      export const sendNewPasswordByClient = async (email, password, code) => {
        console.log(email, code);
          const config = {
            headers: {
              ContentType: `Content-Type': 'application/json`,
            },
          };
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/invest/clients/sendNewPasswordByClient`,
            {email: email,
              password: password,
            code: code,
          },
            config,
          );
          return response.data;
        };

  

      



  export const deleteUser = async (userData, token) => {
    console.log(userData, token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/delete`,
      {login: userData},
      config,
    );
    return response.data;
  };

  export const checkAdmin = async (email, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/admin`,
      {email: email},
      config,
    );
    return response.data;
  };


  export const referalInfo = async (login, token) => {
    console.log(token, login);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/partnerInfo`,
      {login: login},
      config,
    );
    return response.data;
  };



  

  export const getDepositSum = async (email, token) => {
    console.log(token, email);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getDepositSum`,
      {email: email},
      config,
    );
    return response.data;
  };

  export const createDeposit = async (email, token, currentSum, currentTerms, depositEndDate, totalSum, profit, sumAfterDeposit) => {
 
    let dayCount;
    // console.log(currentTerms);
    // debugger;
  // if(currentTerms.time.includes('часа')) {
  //   dayCount = 1;
  // } else {
    dayCount = currentTerms.date.match(/\d+/)[0];
  // }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/createDeposit`,
      {email,
      currentSum,
      depositDays: dayCount,
      procent: currentTerms.procent,
      depositEndDate,
      totalSum,
      profit,
      sumAfterDeposit
      },
      config,
    );
    return response.data;
  };


  export const getAllDeposits = async (email, token) => {
    console.log(token, email);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getAllDeposits`,
      {email: email},
      config,
    );
    return response.data;
  };


  export const getDepositsByAdmin = async (token, currentPage) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getDepositsByAdmin`,
      {currentPage: currentPage},
      config,
    );
    return response.data;
  };

  // export const changeDepositBalanceByAdmin = async (token, emailToUpdate, value) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   const response = await axios.post(
  //     `${process.env.REACT_APP_BASE_URL}/invest/clients/changeDepositBalanceByAdmin`,
  //     {emailToUpdate: emailToUpdate,
  //      value: value
  //     },

  //     config,
  //   );
  //   return response.data;
  // };




  

  
  // export const changeStatusByAdmin = async (token, idToUpdate, value) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   const response = await axios.post(
  //     `${process.env.REACT_APP_BASE_URL}/invest/clients/changeStatusByAdmin`,
  //     {idToUpdate: idToUpdate,
  //      value: value
  //     },

  //     config,
  //   );
  //   return response.data;
  // };


  export const closeDeposit = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/closeDeposit`,
      obj,
      config,
    );
    return response.data;
  };


  export const addAmountToDeposit = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/addAmountToDeposit`,
      obj,
      config,
    );
    return response.data;
  };


  export const subDepositToEmail = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/subDepositToEmail`,
      obj,
      config,
    );
    return response.data;
  };


  export const createDepositRequest = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/createDepositRequest`,
      obj,
      config,
    );
    return response.data;
  };


  export const getAllDepositRequest = async (token, email) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getAllDepositRequest`,
      {email},
      config,
    );
    return response.data;
  };
  
  export const getRequestsByAdmin = async (token, currentPage) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getRequestsByAdmin`,
      {currentPage},
      config,
    );
    return response.data;
  };

    
  export const fillDepositRequest = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/fillDepositRequest`,
      obj,
      config,
    );
    return response.data;
  };

  export const createWithdrawRequest = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/createWithdrawRequest`,
      obj,
      config,
    );
    return response.data;
  };

  export const getAllWithdrawRequest = async (token, email) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getAllWithdrawRequest`,
      {email},
      config,
    );
    return response.data;
  };
  
  
  export const getWithdrawsByAdmin = async (token, currentPage) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getWithdrawsByAdmin`,
      {currentPage: currentPage},
      config,
    );
    return response.data;
  };

  
  

  export const fillWithdrawRequest = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/fillWithdrawRequest`,
      obj,
      config,
    );
    return response.data;
  };

  export const getAllRefs = async (token, email) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getAllRefs`,
      {email},
      config,
    );
    return response.data;
  };

  


  export const getAllClients = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getAllClients`,
      obj,
      config,
    );
    return response.data;
  };

  export const deleteClientByAdmin = async (token, obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/deleteClientByAdmin`,
      obj,
      config,
    );
    return response.data;
  };


  export const getDepositAddress = async () => {

    const config = {
      headers: {
        ContentType: `Content-Type': 'application/json`,
      },
    };
  
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/getDepositAddress`,
      config
    );
    return response.data;
  };



  //by admin!

  export const changeAddressInMethod = async (token, method, newAddress) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/changeAddressInMethod`,
      {method: method,
        newAddress: newAddress
      },
      config,
    );
    return response.data;
  };


  export const changeQrInMethod = async (token, method, newQr) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/invest/clients/changeQrInMethod`,
      {method: method,
        newQr: newQr
      },
      config,
    );
    return response.data;
  };


  

  

  
  