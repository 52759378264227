import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage/Homepage';
import ClientPanel from './pages/ClientPanel/ClientPanel';
import DepositPanel from './pages/DepositPanel/DepositPanel';
import DepositPanelFunds from './pages/DepositPanelFunds/DepositPanelFunds';
import DepositPanelFundsHistory from './pages/DepositPanelFundsHistory/DepositPanelFundsHistory';
import Withdraw from './pages/Withdraw/Withdraw';
import WithdrawHistory from './pages/WithdrawHistory/WithdrawHistory';
import Partner from './pages/Partner/Partner';
import PartnerAll from './pages/PartnerAll/PartnerAll';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import AdminPanelAccount from './pages/AdminPanelAccount/AdminPanelAccount';
import AdminPanelWithdraw from './pages/AdminPanelWithdraw/AdminPanelWithdraw';
import AdminPanelCustomer from './pages/AdminPanelCustomer/AdminPanelCustomer';
import AdminPanelChat from './pages/AdminPanelChat/AdminPanelChat';
import ChangePasswordElement from './pages/ChangePasswordElement/ChangePasswordElement';
import AdminPanelAddress from './pages/AdminPanelAddress/AdminPanelAddress';





import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';

import NotFound from './pages/NotFound/NotFound';
import Redirect from './pages/Redirect/Redirect';
import Error from './pages/404/404';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import Login from './pages/Login/Login';
import Header from './components/Header';
import SidebarComponent from "./components/SidebarComponent/SidebarComponent"
import AdminHeader from "./components/AdminHeader/AdminHeader"








const WrapperWithClientHeader = ({children}) => {
  return(
    <>
    <Header />
    <div id="outer-container">
    <SidebarComponent />
    <main id="page-wrap">
    {children}
    </main>
    </div>
    </>
  )
}


const WrapperWithAdminHeader = ({children}) => {
  return(
    <>
    <AdminHeader />
    {children}
    </>
  )
}




export const UseLogOutRoutes = () => {
  console.log("UseLogOutRoutes");
    return (
      <Routes>
        <Route exact path='/' element={ <Homepage />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recoveryPassword" element={<ChangePasswordElement />} />
        <Route exact path='*' element={<Error />} />
      
      </Routes>
    );
  };

  export const UseClientRoutes = () => {
    return (
      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />

        <Route exact path='/client' element={
        <WrapperWithClientHeader>
          <ClientPanel />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/deposit' element={
        <WrapperWithClientHeader>
          <DepositPanel />
          </WrapperWithClientHeader>} />
          
          <Route exact path='/client/deposit-funds' element={
        <WrapperWithClientHeader>
          <DepositPanelFunds />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/deposit-funds-history' element={
        <WrapperWithClientHeader>
          <DepositPanelFundsHistory />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/withdraw' element={
        <WrapperWithClientHeader>
          <Withdraw />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/withdraw-history' element={
        <WrapperWithClientHeader>
          <WithdrawHistory />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/partner' element={
        <WrapperWithClientHeader>
          <Partner />
          </WrapperWithClientHeader>} />

          <Route exact path='/client/partner-all' element={
        <WrapperWithClientHeader>
          <PartnerAll />
          </WrapperWithClientHeader>} />

          <Route exact path='/confirmEmail' element={
          <ConfirmEmail />
          } />


  

        <Route exact path='*' element={<Error />} />
      </Routes>
    );
  };


export const UseAdminRoutes = () => {
    return (
      <Routes>
        <Route exact path='/adminPanel' element={
        <WrapperWithAdminHeader>
            <AdminPanel />
        </WrapperWithAdminHeader>
      } />
        <Route exact path='/adminPanelAccount' element={
        <WrapperWithAdminHeader>
            <AdminPanelAccount />
        </WrapperWithAdminHeader>
       } />
        <Route exact path='/adminPanelWithdraw' element={
        <WrapperWithAdminHeader>
            <AdminPanelWithdraw />
        </WrapperWithAdminHeader>
       } />
          <Route exact path='/AdminPanelCustomer' element={
        <WrapperWithAdminHeader>
            <AdminPanelCustomer />
        </WrapperWithAdminHeader>
       } />
         <Route exact path='/AdminPanelChat' element={
        <WrapperWithAdminHeader>
            <AdminPanelChat />
        </WrapperWithAdminHeader>
       } />
         <Route exact path='/adminPanelAddress' element={
        <WrapperWithAdminHeader>
            <AdminPanelAddress />
        </WrapperWithAdminHeader>
       } />
        <Route exact path='*' element={<Redirect  />} />
      </Routes>
    );
  };
  


  

  